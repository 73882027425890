import { Component, Signal, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { PriceScaleCollection } from 'src/app/shared/models/availabilty/section.model';
import { Package } from 'src/app/shared/models/package.model';

@Component({
  selector: 'app-select-package',
  templateUrl: './select-package.component.html',
  styleUrl: './select-package.component.css'
})
export class SelectPackageComponent {

  constructor(
    private availability: AvailabilityService<Package, PriceScaleCollection>
  ){}

  //SERVICES
  private router:         Router            = inject(Router);
  private params:         Params            = inject(ActivatedRoute).snapshot.queryParams;

  protected packages:     Signal<Package[]> = this.availability.entity;

  public title:           string            =  'Packages'

  protected nextStep(packageSelected: Package): void{
   
    const route: string = '/buy-packages/select-friends/'
    const params: any   = {
      package:  packageSelected.id,
      venue:    packageSelected.venue
    }

    if(this.params['transaction']){
      params.transaction = this.params['transaction'];
    }
    
    this.availability.setEntitySelected(packageSelected);
    this.router.navigate([route],{queryParams: params});
  }
}
