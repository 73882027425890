import { Component } from '@angular/core';

@Component({
  selector: 'app-buy-packages',
  templateUrl: './buy-packages.component.html',
  styleUrl: './buy-packages.component.css'
})
export class BuyPackagesComponent {

}
