<!-- TITLE -->
<div class="flex flex-col items-center p-4 lg:p-6 container mx-auto w-full">
    
    <h3 class="text-darkgray dark:text-lightgray/60 font-bold text-3xl mb-4 lg:text-4xl">
        {{title}}
    </h3>

</div>

<!-- PACKAGES -->
<div *ngIf="packages() else emptyPackages" [ngClass]="{'justify-center items-center bg-transparent mt-32 lg:mt-0 lg:bg-white': !packages}" class="h-full p-4 lg:py-8 bg-white dark:bg-gray-900 flex flex-col md:items-center gap-5 md:overflow-y-auto">
    
    <!-- LOADER -->
    <ng-container *ngIf="packages().length === 0 else packageCards">
        <div class="h-[calc(theme(height.screen)-200px)] flex items-center bg-white justify-center">
            <app-loader class="flex justify-center"/>
        </div>
    </ng-container>
    
    <!-- CARDS -->
    <ng-template #packageCards>
        <app-card class="flex w-full justify-center" *ngFor="let package of packages()" [package]="package" (cardEvents)="nextStep(package)"/>
    </ng-template>

</div>

<!-- NO PACKAGES -->
<ng-template #emptyPackages>
    <div class="h-full w-full bg-white text-center py-5">
        <h5 class="font-main text-xl text-darkgray mb-2">
            Package Schedule
        </h5>
        <p class="font-main text-secondary">
            There are no packages available at this time
        </p>
    </div>
</ng-template>


