import { DestroyRef, Injectable } from '@angular/core';
import { AvailabilityService } from '../../availability.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Package } from 'src/app/shared/models/package.model';
import { PriceScaleCollection } from 'src/app/shared/models/availabilty/section.model';
import { ModalService } from '../../modal.service';
import { SeatCollection } from 'src/app/shared/models/availabilty/seat.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PackageService extends AvailabilityService<Package,PriceScaleCollection | SeatCollection> {

  constructor(
    destroy:  DestroyRef,
    http:     HttpClient,
    route:    ActivatedRoute,
    router:   Router,
    modal:    ModalService
  ) {
    super(destroy, http, route, router, modal)
  }
  protected isSelectSeat:           boolean = false;
  protected override routeParams:   string  = 'package';
  protected override returnRoute:   string  = 'buy-packages';
  protected override get rootUrl(): string {
    return '/packages/'
  }
  
  public setSelectSeatStatus(status: boolean): void {
    this.isSelectSeat = status;
  }

  public override refresh(additionalId?: string | undefined): void {
    
    const entity: Package = this.entitySelected() as Package;
    
    this.resetAvailability();

    additionalId ? this.getAvailabiltyHandler(entity.id, additionalId) : this.getAvailabiltyHandler(entity.id);
  }

  public override getSectionAvailability(event: string|number, id: string|number): Observable<SeatCollection> {
    
    let url = this.rootUrl + event + this.endpoint + id;
   
    return this.http.get<SeatCollection>(url, {params: this.getAvailabilityParams()}).pipe(
      map((data: SeatCollection) => {
      
      const formattedSeatCollection: SeatCollection = {};

      Object.entries(data).forEach(([key, value]) => {
        const finalKey = `${id}-${key}`;
        formattedSeatCollection[finalKey] = value;
      });

      return formattedSeatCollection;
    
    }))
    
  }

  protected override getAvailabilityParams(): HttpParams {
    if(this.entitySelected() as Package){
      
      let params: any = {}

      if(this.isSelectSeat){
        params['purchase_flow'] = 'select_selection'
      }

      return new HttpParams({fromObject:params})
    }else{
      return new HttpParams();
    }
  }

  
}
