import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// COMPONENTS
import { SelectPackageComponent } from "./select-package/select-package.component";
import { SelectFriendsComponent } from "../select-friends/select-friends.component";
import { SelectSeatComponent } from "../select-seat/select-seat.component";
import { CheckoutComponent } from "../checkout/checkout.component";
import { SummaryComponent } from "../summary/summary.component";

const routes: Routes = [
    {
      path: '',
      component: SelectPackageComponent,
      title: 'Select Package'
    },
    {
      path: 'select-friends',
      component: SelectFriendsComponent,
      title: 'Select Friends'
    },
    {
      path: 'select-seat',
      component: SelectSeatComponent,
      title: 'Select a seat',
      data: {
        type: 'package'
      }
    },{
      path: 'checkout',
      component: CheckoutComponent,
      title: 'Checkout'
    },
    {
      path: 'summary/:status',
      component: SummaryComponent,
      title: 'Summary | Buy Packages'
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BuyPackagesRoutingModule {}