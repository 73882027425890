import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ROUTES
import { BuyPackagesRoutingModule } from './buy-packages-routing.module';

//COMPONENTS
import { BuyPackagesComponent } from './buy-packages.component';
import { SelectPackageComponent } from './select-package/select-package.component';

// MODULES
import { SelectFriendsModule } from '../select-friends/select-friends.module';
import { SelectSeatModule } from '../select-seat/select-seat.module';
import { CardModule } from 'src/app/shared/components/globals/card/card.module';
import { FooterModule } from 'src/app/shared/components/globals/footer/footer.module';
import { DvmModule } from '@3ddv/ngx-dvm-internal';
import { SelectorModule } from 'src/app/shared/components/globals/selector/selector.module';
import { CheckoutModule } from '../checkout/checkout.module';
import { LoaderModule } from 'src/app/shared/components/globals/loader/loader.module';

// SERVICES
import { PackageService } from 'src/app/core/services/override/availability/package.service';
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { PackageTransactionService } from 'src/app/core/services/override/checkout/package-transaction.service';
import { SummaryModule } from '../summary/summary.module';

@NgModule({
  declarations: [
    BuyPackagesComponent,
    SelectPackageComponent,
  ],
  imports: [
    CommonModule,
    SelectFriendsModule,
    SelectSeatModule,
    CardModule,
    FooterModule,
    DvmModule,
    SelectorModule,
    CheckoutModule,
    LoaderModule,
    SummaryModule,
    BuyPackagesRoutingModule
  ],
  providers: [
    {provide: AvailabilityService, useClass: PackageService},
    {provide: CheckoutService, useClass: PackageTransactionService }
  ]
})
export class BuyPackagesModule {}
